import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { makeAutoObservable } from 'mobx';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiStore {
  isSideFormOpen: boolean = false;
  isEnableSideFilter: boolean = false;
  isOpenSideFilter: boolean = false;
  saving: boolean = false;
  loading: boolean = false;
  formErrors: string[] = [];

  private renderer: Renderer2;

  constructor(private router: Router, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);

    makeAutoObservable(this);
    this.listenToRouteChanges();
  }

  closeSideForm() {
    this.isSideFormOpen = false;
  }

  openSideForm() {
    this.isSideFormOpen = true;
  }

  enableSideFilter() {
    this.isEnableSideFilter = true;
  }

  disableSideFilter() {
    this.isEnableSideFilter = false;
    this.closeSideFilter();
  }

  openSideFilter() {
    this.isOpenSideFilter = true;
  }

  closeSideFilter() {
    this.isOpenSideFilter = false;
  }

  private listenToRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        // this.disableSideFilter();
        this.removeBackdrops();
      });
  }

  private removeBackdrops() {
    const backdrops = document.querySelectorAll('.modal-backdrop');
    backdrops.forEach((backdrop) =>
      this.renderer.removeChild(backdrop.parentNode, backdrop)
    );
  }

  startSaving() {
    this.formErrors = [];
    this.saving = true;
  }
  finishSaving() {
    this.saving = false;
  }

  startLoading() {
    this.loading = true;
  }

  finishLoading() {
    this.loading = false;
  }
}
